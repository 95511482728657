import { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import LocationAutocomplete from 'components/LocationAutocomplete/LocationAutocomplete';
import { ILocationMiniInfo } from 'interfaces/location.interface';
import SLocationService from 'services/location/location.service';
import SUserService from 'services/user/user.service';
import { IUserDetails } from 'interfaces/user.interface';
import { TeamsFragment } from 'pages/TSOSetup/TeamsSetup';
import { getInformationBarrierScript } from 'utils/informationBarrier.utils';
import { mapDomain } from 'constants/user';
import NewUserDialog from './NewUserDialog';
import { TeamsFormData } from 'interfaces/teams.interface';

export interface CurrentTeamsDetails {
  teamsId: string | undefined;
  channelId: string | undefined;
}

const Teams: FunctionComponent = () => {
  const [newUserDialogue, setNewUserDialogue] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState<
    number | undefined
  >();
  const [userAADObjectId, setUserAADObjectId] = useState<string | undefined>();
  const [isTeamsCreated, setIsTeamsCreated] = useState(false);
  const [script, setScript] = useState<string | undefined>();
  const [isCopied, setIsCopied] = useState(false);
  const [locationsList, setLocationsList] = useState<ILocationMiniInfo[]>([]);
  const [selectedProject, setSelectedProject] =
    useState<ILocationMiniInfo | null>();
  const [usersList, setUsersList] = useState<IUserDetails[]>([]);
  const [userPrincipalName, setUserPrincipalName] = useState<
    string | undefined
  >();
  const [currentTeamsData, setCurrentTeamsData] = useState<
    CurrentTeamsDetails | undefined
  >();

  const [teamsFormData, setTeamsFormData] = useState<TeamsFormData>({
    teamsDetails: {
      teamsName: '',
      channelName: '',
      teamsDescription: '',
      channelDescription: '',
      teamsId: '',
      channelId: '',
      notificationLink: '',
    },
    memberAADObjectId: '',
    ownerAADObjectId: '',
    projectId: '',
  });

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    SUserService.getUser().then((response: any) => {
      setUserPrincipalName(response.userPrincipalName);
    });
    SLocationService.findNames()
      .then((response) => {
        setLocationsList(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (
      isTeamsCreated &&
      teamsFormData.teamsDetails.teamsName &&
      userPrincipalName
    ) {
      const originalTeamsName = teamsFormData.teamsDetails.teamsName;
      setScript(
        getInformationBarrierScript(
          originalTeamsName,
          originalTeamsName,
          'MemberOf',
          originalTeamsName.replace(/\s+/g, '') + mapDomain[0].value,
          userPrincipalName
        )
      );
    }
  }, [isTeamsCreated]);

  const handleSelectUser = (selectedUserId: number) => {
    const userSelected = usersList.find((user) => user.id === selectedUserId);
    if (userSelected?.aadObjectId) setUserAADObjectId(userSelected.aadObjectId);
  };

  const fetchAllUsersByLocation = (locationId: number) => {
    SUserService.getUsersByLocationId(locationId)
      .then((response) => {
        setUsersList(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleLocationChange = async (
    e: SyntheticEvent<Element, Event>,
    location: ILocationMiniInfo | null
  ) => {
    setCurrentTeamsData(undefined);
    setIsTeamsCreated(false);
    setUserAADObjectId(undefined);
    if (location?.id) {
      fetchAllUsersByLocation(location.id);
      setSelectedLocationId(location.id);
      const locationDetails = await SLocationService.getLocationDetails(
        location.id
      );
      if (locationDetails.MSTeamsID && locationDetails.MSTeamsChannel) {
        setCurrentTeamsData({
          teamsId: locationDetails?.MSTeamsID,
          channelId: locationDetails?.MSTeamsChannel,
        });
      }
    }
    setSelectedProject(location);
  };

  const handleCopyClick = () => {
    if (script) {
      navigator.clipboard.writeText(script);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };

  return (
    <div>
      <Grid container spacing={2} className="main-grid-container">
        <Grid item xs={12}>
          Teams Creation & Updation
        </Grid>
        <Grid item xs={12}>
          <FormControl required sx={{ mb: 5, minWidth: 360 }}>
            {locationsList?.length ? (
              <LocationAutocomplete
                locationList={locationsList}
                onSelectedLocationChange={handleLocationChange}
              />
            ) : (
              'No projects present'
            )}
          </FormControl>
        </Grid>
        {selectedProject && (
          <>
            <Grid item xs={12}>
              {usersList?.length ? (
                <FormControl required sx={{ mb: 5, minWidth: 360 }}>
                  <InputLabel id="user-select-label">User</InputLabel>
                  <Select
                    labelId="user-select-label"
                    id="user-select"
                    label="User"
                    defaultValue={''}
                    onChange={(event) =>
                      handleSelectUser(Number(event.target.value))
                    }
                  >
                    {usersList?.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        <div className="user-select-menu-items">
                          {user.emailAddress}
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => setNewUserDialogue(true)}
                >
                  Add User
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              {currentTeamsData && !isTeamsCreated && (
                <Typography>
                  <h3>Current Teams Details</h3>

                  {/* After cancelling the columns MSTeamsName and reusing MSTeamsChannel for ID of the channel it
                  became impossible to display name here, at least with my knowledge. 
                  https://learn.microsoft.com/en-us/graph/api/team-get?view=graph-rest-1.0&tabs=http */}
                  <span>Teams ID : {currentTeamsData.teamsId}</span>
                  <br />
                  <span>Channel ID : {currentTeamsData.channelId}</span>
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              {selectedLocationId ? (
                userAADObjectId && (
                  <>
                    <TeamsFragment
                      formData={teamsFormData}
                      setFormData={setTeamsFormData}
                      setTeamsCreated={setIsTeamsCreated}
                      projectId={selectedProject.id}
                      locationId={selectedLocationId}
                      userAADObjectId={userAADObjectId}
                    />
                    <Paper square elevation={0} sx={{ p: 3 }}>
                      {isTeamsCreated && (
                        <Typography>
                          Please add the Barrier Policies. Open your Windows
                          PowerShell in Administrator Mode and copy and run the
                          following code snippet.
                          <div className="code-snippet-container">
                            <code className="code-snippet-code">{script}</code>
                            <div className="copy-button-container">
                              <button
                                className="copy-button"
                                onClick={handleCopyClick}
                              >
                                {isCopied ? 'Copied!' : 'Copy to Clipboard'}
                              </button>
                            </div>
                          </div>
                        </Typography>
                      )}
                    </Paper>
                  </>
                )
              ) : (
                <div>
                  There is No Locations Associated with the Project. Creation of
                  Teams and Channel is not possible
                </div>
              )}
            </Grid>
            <NewUserDialog
              open={newUserDialogue}
              projectId={selectedProject.id}
              projectName={selectedProject.name}
              handleClose={() => setNewUserDialogue(false)}
              refreshUsersList={() => {
                fetchAllUsersByLocation(selectedProject.id);
              }}
            />
          </>
        )}
      </Grid>
    </div>
  );
};

export default Teams;
