/*******************************************************
Copyright (C) 2024 H2 Digital GmbH - info@h2-digital.com , Inc - All Rights Reserved
Proprietary and confidential.
Unauthorized copying of this file, via any medium is strictly prohibited.
Written by Silpa Sivadas, silpa.sivadas@h2-digital.com, January 2024
*******************************************************/
import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import './TSOSetup.scss';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { ProjectFragment } from './ProjectSetup';
import { UserFragment } from './UserSetup';
import SProjectService from 'services/project/project.service';
import SLocationService from 'services/location/location.service';
import SUserService from 'services/user/user.service';
import { DomainNames } from 'interfaces/user.interface';
import { Description, Factory, Person } from '@mui/icons-material';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import { TeamsFragment } from './TeamsSetup';
import STeamsService from 'services/teams/teams.service';
import { h2DigitalTeamsForNotification } from 'config/authConfig';
import { addNewUserToProjectMsgTeams } from 'utils/teamsMessage';
import { PowerBIFragment } from './PowerBIReportSetup';
import { INewUserDetails, IUserDetailsError } from 'interfaces/user.interface';
import { mapDomain, UserTypes } from 'constants/user';
import { useSnackbar } from 'components/snackbar/SnackbarProvider';
import { NewUserValidator } from 'utils/NewUserValidator';
import { debounce, matches } from 'lodash';
import SUserDetailsService from 'services/userDetails/userDetails.service';
import { getInformationBarrierScript } from 'utils/informationBarrier.utils';
import { TeamsFormData } from 'interfaces/teams.interface';
import { ILocationDetails } from 'interfaces/location.interface';
import { LocationType } from 'constants/location';

const TSOSetup: FunctionComponent = () => {
  const defaultLocationDetails = {
    name: '',
    description: '',
    H2DigitalType: LocationType.Provider,
    cOGEDigitization: 'D-no',
    address: '',
  };
  const defaultUserValues = {
    firstName: '',
    lastName: '',
    userName: '',
    secondaryEmail: '',
    domain: DomainNames.h2Digital,
    isAdmin: true,
    isLocked: false,
    isSuperUser: false,
    userType: UserTypes.Provider,
    tagIds: [],
  };
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [userAADObjectId, setUserAADObjectId] = useState<string>('');

  const [providerLocationDetails, setProviderLocationDetails] = useState<
    Partial<ILocationDetails>
  >(defaultLocationDetails);
  const emptyBlob = new Blob([], { type: 'application/octet-stream' });

  const [projectLogoFile, setProjectLogoFile] = useState<Blob>(emptyBlob);
  const [projectImageFile, setProjectImageFile] = useState<Blob>(emptyBlob);

  const [isCreateProjectEnabled, setCreateProjectEnabled] = useState(false);
  const [newUserDetails, setNewUserDetails] =
    useState<INewUserDetails>(defaultUserValues);
  const [userAvatar, setUserAvatar] = useState<Blob>();
  const [isReportAdded, setReportsAdded] = useState(false);
  const [isTeamsCreated, setTeamsCreated] = useState(false);
  const [userNameExists, setUserNameExists] = useState<boolean>();
  const [script, setScript] = useState<string | undefined>(undefined);
  const [isCopied, setIsCopied] = useState(false);
  const { showSnackbar } = useSnackbar();
  const [userPrincipalName, setUserPrincipalName] = useState<string>();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const skipToLast = () => {
    setActiveStep(4); //last step
  };
  const [teamsFormData, setTeamsFormData] = useState<TeamsFormData>({
    teamsDetails: {
      teamsName: '',
      channelName: '',
      teamsDescription: '',
      channelDescription: '',
      teamsId: '',
      channelId: '',
      notificationLink: '',
    },

    memberAADObjectId: '',
    ownerAADObjectId: '',
    projectId: '',
  });
  const [userDetailsError, setUserDetailsError] = useState<IUserDetailsError>({
    firstName: '',
    lastName: '',
    userName: '',
    secondaryEmail: '',
    userAvatar: '',
  });

  const handleReset = () => {
    setActiveStep(0);
    setProviderLocationDetails(defaultLocationDetails);
    setNewUserDetails(defaultUserValues);
    setUserAADObjectId('');
  };

  const createNewProject = () => {
    if (providerLocationDetails.name) {
      setLoading(true);
      const providerDetailsWithTag = {
        ...providerLocationDetails,
        goLiveDate: new Date().toISOString(),
        tags: [{ key: 'Provider', value: providerLocationDetails.name }],
      };
      SProjectService.createNewProvider(providerDetailsWithTag)
        .then((locationDetails) => {
          setProviderLocationDetails(locationDetails);
          SLocationService.uploadImage(projectImageFile, locationDetails.id);
          SLocationService.uploadLogo(projectLogoFile, locationDetails.id);
          setProjectLogoFile(emptyBlob);
          setProjectImageFile(emptyBlob);
          setNewUserDetails({
            ...newUserDetails,
            tagIds: [locationDetails.tags[0].id],
          });
          handleNext();
          showSnackbar('Provider project creation success!', 'success');
        })
        .catch(() => {
          setLoading(false);
          showSnackbar('Provider project creation failed!', 'error');
        })
        .finally(() => setLoading(false));
    }
  };

  const checkUserNameExists = (signal: AbortSignal) => {
    const userid =
      newUserDetails.userName?.toLowerCase() + newUserDetails.domain;
    const delayedCheck = debounce(async () => {
      const exists = await SUserDetailsService.checkH2DigitalUserExists(
        userid,
        signal
      );
      setUserNameExists(exists.data);
    }, 500);
    delayedCheck();
  };
  useEffect(() => {
    SUserService.getUser().then((response: any) => {
      setUserPrincipalName(response.userPrincipalName);
    });
  });
  useEffect(() => {
    setUserNameExists(undefined);
    const controller = new AbortController();
    const signal = controller.signal;
    if (newUserDetails.userName?.trim().length) checkUserNameExists(signal);
    else setUserNameExists(undefined);
    return () => {
      controller.abort();
    };
  }, [newUserDetails.userName, newUserDetails.domain]);

  const createNewUser = () => {
    if (!isLoading) {
      setUserDetailsError({
        firstName: '',
        lastName: '',
        userName: '',
        secondaryEmail: '',
        userAvatar: '',
      });
      const errors = NewUserValidator(newUserDetails, userAvatar);
      setUserDetailsError((prev) => {
        return { ...prev, ...errors };
      });
      if (
        matches(errors)({}) &&
        userAvatar &&
        !userNameExists &&
        providerLocationDetails.projectId
      ) {
        setLoading(true);
        SProjectService.addNewUserToProject(
          providerLocationDetails.projectId,
          newUserDetails
        )
          .then((res) => {
            setLoading(false);
            showSnackbar(
              'Successfully created. Please proceed to the next step',
              'success'
            );
            setUserAADObjectId(res.data.aadObjectId);
            if (res.data.user) {
              const projectName = providerLocationDetails.name;
              res.data = { ...res.data, projectName };
              const message = addNewUserToProjectMsgTeams(res.data);
              STeamsService.sendNotificationInTeams(
                h2DigitalTeamsForNotification.link,
                message
              );
            }
            setLoading(false);
            SUserService.uploadAvatar(userAvatar, res.data.user.userId);
            handleNext();
            showSnackbar(
              'Provider user creation success. Please complete teams set up also.',
              'success'
            );
          })
          .catch(() => {
            setLoading(false);
            showSnackbar('Provider user creation failed.', 'error');
          });
      }
    }
  };

  const steps = [
    {
      key: 'project',
      label: (
        <div>
          {providerLocationDetails.projectId
            ? `Created Project: ${providerLocationDetails.name}`
            : `Project Details`}
        </div>
      ),
      icon: <Factory className="stepper-icon" />,
      description: `Please fill in the Project Details.`,
      fragment: (
        <ProjectFragment
          providerLocationDetails={providerLocationDetails}
          setProviderLocationDetails={setProviderLocationDetails}
          projectLogoFile={projectLogoFile}
          setProjectLogoFile={setProjectLogoFile}
          projectImageFile={projectImageFile}
          setProjectImageFile={setProjectImageFile}
          setCreateProjectEnabled={setCreateProjectEnabled}
        />
      ),

      nextButton: 'Create Project',
      nextButtonFunction: createNewProject,
      isDisabled: isCreateProjectEnabled,
    },
    {
      key: 'powerbi',
      label: 'PowerBI Reports',
      icon: <Description className="stepper-icon" />,
      description: `Please add all the relevant powerBI Report details for the Provider.`,
      fragment: (
        <PowerBIFragment
          setReportsAdded={setReportsAdded}
          isReportAdded={isReportAdded}
          projectId={providerLocationDetails.projectId}
        />
      ),
      nextButton: isReportAdded ? 'Continue' : 'Skip',
      isDisabled: false,
    },
    {
      key: 'user',
      label: (
        <div>
          {userAADObjectId
            ? `Created User: ${
                newUserDetails.userName?.toLowerCase() + newUserDetails.domain
              }`
            : `User Details`}
        </div>
      ),

      icon: <Person className="stepper-icon" />,
      description: `Please fill in the Provider user details.`,
      fragment: (
        <UserFragment
          newUserDetails={newUserDetails}
          setNewUserDetails={setNewUserDetails}
          setUserDetailsError={setUserDetailsError}
          userLogoFile={userAvatar}
          setUserLogoFile={setUserAvatar}
          userDetailsError={userDetailsError}
          userNameExists={userNameExists}
          createNewUser={createNewUser}
        />
      ),
      nextButton: 'Skip',
      nextButtonFunction: skipToLast, //go to end step
    },
    {
      key: 'teams',
      label: 'Teams Details',
      icon: <AttachEmailIcon className="stepper-icon" />,
      description: `Please fill in the MSTeams Details of the Provider.`,
      fragment: (
        <TeamsFragment
          userName={
            newUserDetails.userName?.toLowerCase() + newUserDetails.domain
          }
          formData={teamsFormData}
          setFormData={setTeamsFormData}
          setTeamsCreated={setTeamsCreated}
          projectId={providerLocationDetails.projectId}
          locationId={providerLocationDetails.id}
          userAADObjectId={userAADObjectId}
        />
      ),
    },
  ];
  const handleCopyClick = () => {
    if (script) {
      navigator.clipboard.writeText(script);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  };
  useEffect(() => {
    if (
      isTeamsCreated &&
      teamsFormData.teamsDetails.teamsName &&
      userPrincipalName
    ) {
      const originalTeamsName = teamsFormData.teamsDetails.teamsName;
      //TODO: get ms365 group (email) id and add unique segment,policy names
      setScript(
        getInformationBarrierScript(
          originalTeamsName,
          originalTeamsName,
          'MemberOf',
          originalTeamsName.replace(/\s+/g, '') + mapDomain[0].value,
          userPrincipalName
        ) as string
      );
    }
  }, [isTeamsCreated]);
  return (
    <>
      <div className="page-container">
        <Grid container spacing={2} className="main-grid-container">
          <Grid xs={12} xl={10}>
            <Box>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step) => (
                  <Step key={step.key}>
                    <StepLabel
                      StepIconComponent={() => (
                        <div className="stepper-icon-container">
                          {step.icon}
                        </div>
                      )}
                    >
                      {step.label}
                    </StepLabel>
                    <StepContent>
                      <Typography className="step-description">
                        {step.description}
                      </Typography>
                      <Fragment>{step.fragment}</Fragment>
                      <Box sx={{ mb: 2 }}>
                        <div>
                          {step.nextButton && (
                            <Button
                              className="step-button"
                              variant="contained"
                              onClick={step.nextButtonFunction || handleNext}
                              sx={{ mt: 1, mr: 1 }}
                              disabled={isLoading || step.isDisabled}
                            >
                              {isLoading ? (
                                <CircularProgress size={'20px'} />
                              ) : (
                                step.nextButton
                              )}
                            </Button>
                          )}
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                  {isTeamsCreated && (
                    <Typography>
                      Please add the Barrier Policies. Open your Windows
                      PowerShell in Administrator Mode and copy and run the
                      following code snippet.
                      <div className="code-snippet-container">
                        <code className="code-snippet-code">{script}</code>
                        <div className="copy-button-container">
                          <button
                            className="copy-button"
                            onClick={handleCopyClick}
                          >
                            {isCopied ? 'Copied!' : 'Copy to Clipboard'}
                          </button>
                        </div>
                      </div>{' '}
                    </Typography>
                  )}

                  <Button
                    variant="contained"
                    onClick={handleReset}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Create a New Provider
                  </Button>
                </Paper>
              )}
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default TSOSetup;
