export enum PowerBIReportTypes {
  SIZINGANDCOSTING = 'SIZINGANDCOSTING',
  SUMMARY = 'SUMMARY',
}

export enum ReportStatus {
  ACTIVE = 'ACTIVE',
  HIDE = 'HIDE',
}

export const defaultReportError = {
  reportId: '',
  datasetId: '',
  workspaceId: '',
};

export const defaultReportDetails = {
  powerBIReportTypes: PowerBIReportTypes.SUMMARY,
  reportStatus: ReportStatus.ACTIVE,
  reportId: '',
  datasetId: '',
  workspaceId: '',
  reportName: '',
  roleName: '',
};
