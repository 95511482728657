import { emailExpression } from 'constants/common';
import { INewUserDetails } from 'interfaces/user.interface';

export const NewUserValidator = (
  newUserDetails: INewUserDetails,
  userAvatar: Blob | undefined
) => {
  let userError = {};
  if (newUserDetails.firstName === '' || newUserDetails.firstName === null) {
    userError = {
      ...userError,
      firstName: 'First Name cannot be empty!',
    };
  }
  if (newUserDetails.lastName === '' || newUserDetails.lastName === null) {
    userError = {
      ...userError,
      lastName: 'Last Name cannot be empty!',
    };
  }
  if (newUserDetails.userName === '' || newUserDetails.userName === null) {
    userError = {
      ...userError,
      userName: 'User Name cannot be empty!',
    };
  } else if (newUserDetails.userName.includes(' ')) {
    userError = {
      ...userError,
      userName: 'White space not allowed!',
    };
  }
  if (userAvatar === undefined) {
    userError = {
      ...userError,
      userAvatar: 'User Avatar cannot be empty!',
    };
  }
  if (
    newUserDetails.secondaryEmail.trim() === '' ||
    newUserDetails.secondaryEmail === null
  ) {
    userError = {
      ...userError,
      secondaryEmail: 'Secondary email cannot be empty!',
    };
  } else {
    const result = emailExpression.test(newUserDetails.secondaryEmail);
    if (!result) {
      userError = {
        ...userError,
        secondaryEmail: 'Enter a valid mail id!',
      };
    }
  }

  return userError;
};
