export const addNewUserToProjectMsgTeams = (data: {
  projectName: string;
  projectId: string;
  user: { username: string; password: string };
}) => {
  const message =
    'A new User is added to the project ' +
    data.projectName +
    ' with PROJECT id : ' +
    data.projectId +
    '. ' +
    'The username is ' +
    data.user.username +
    ' and password ' +
    data.user.password;
  return message;
};
